<template>
  <div class=" container-fluid crypto-wrapper p-5">
    <section class="row">
      <h1 class="text-white">My deposit</h1>
    </section>
    <section class="row mt-4">
      <div class="col-lg-6 mb-4 mb-lg-0">
        <div class="card p-3">
          <div class="card-header text-center">
            <div class="row">
              <div class="col-4 text-start text-second">
                <h4>Total balance</h4>
              </div>
              <div class="col-3 button d-flex align-items-baseline">
                <button>Deposit</button>
              </div>
              <div class="col-3 button d-flex align-items-baseline">
                <button>Withdraw</button>
              </div>
              <div class="col-2 button d-flex align-items-baseline">
                <button>Send</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h4>9,564,00 <span>usdc</span></h4>
            <div class="row">
              <div class="col-md-6">
                <p>Interest earning this year</p>
                <h5>101.01 USDC</h5>
              </div>
              <div class="col-md-2 horizontal-line d-none d-md-block"></div>
              <div class="col-md-4 ps-md-5">
                <p>Current inerest rate</p>
                <h5>23%</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4 mb-md-0">
        <div class="card p-3">
          <div class="card-header text-center">
            <h4 class="text-second text-start">Current exchange rates</h4>
          </div>
          <div class="card-body">
            <h4>70.00 <span>ars</span> -> 1 <span>usdc</span></h4>
            <div class="row">
              <div class="col-md-6">
                <p>Exchange rate 1 year ago</p>
                <h5>43.45 ARS -> 1 USDC</h5>
              </div>
              <div class="col-md-2 horizontal-line d-none d-md-block"></div>
              <div class="col-md-4 ps-md-5">
                <p>USDC vs ARS over 1 year</p>
                <h5 style="color: #0ECB81">+ 61.10%</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="row">
      <div class="col">
        <div class="card mt-5 p-2 card_table">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4 class="text-white">Balance details</h4>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-6">
                  <input type="text" class="w-75 p-2" />
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <input type="checkbox" id="balance" />
                  <label class="ms-2 text-white" for="balance"
                    >Hide Small Balances</label
                  >
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <p class="text-warning m-0 text-decoration-underline">
                    Convert Small Balance to BNB
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body d-flex d-md-block">
            <div class="row flex-column flex-md-row name_column">
              <div class="col">
                <p>Date</p>
              </div>
              <div class="col">
                <p>Coin</p>
              </div>
              <div class="col">
                <p>Total</p>
              </div>
              <div class="col">
                <p>Available</p>
              </div>
              <div class="col">
                <p>Type</p>
              </div>
              <div class="col">
                <p>Action</p>
              </div>
            </div>
            <div
              class="row mt-md-3 ms-3 ms-md-0 flex-column flex-md-row column-info"
            >
              <div class="col p-0">
                <p>1.04.2022</p>
              </div>
              <div class="col">
                <div class="row align-items-center">
                  <div class="col-3 d-none d-md-block">
                    <div class="avatar"></div>
                  </div>
                  <div class="col col-9">
                    <p>BTC</p>
                    <p class="text-under">BMB</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>0.00382689</p>
              </div>
              <div class="col">
                <p>0.00000000</p>
              </div>
              <div class="col">
                <p style="color: #0ECB81 !important">
                  Replenishment
                </p>
              </div>

              <div class="col">
                <p id="action-text">Buy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./style.scss" scoped></style>
